import { adminAxios } from "../helpers/api";

export const getOrderDetail = (orderId) =>
  adminAxios.get(`invoices/${orderId}`, {
    params: {
      include: "invoicePriceDetails,orders.product,paymentMethod,orderType",
    },
  });

export const cancelOrderDetail = (orderId) =>
  adminAxios.delete(`invoices/${orderId}/cancel`);

export const updateOrderDate = (orderId, date) =>
  adminAxios.put(`invoices/${orderId}/date?date=${date}`);
