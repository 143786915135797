import { Box, Button, Divider, TextField, Typography } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import React, { useState } from "react";
import { updateOrderDate } from "../api/orderDetail";
import { invoiceStatusLabel } from "../helpers/general";
import useAuth from "../hooks/useAuth";

const OrderDetailData = ({ orderDetails, setIsChange }) => {
  const [isUpdateDate, setIsUpdateDate] = useState(false);
  const { permissions } = useAuth();
  const [date, setDate] = useState(dayjs(orderDetails?.createdAt));

  console.log({ orderDetails });

  const handleChangeDate = async () => {
    if (orderDetails?.id) {
      await updateOrderDate(
        orderDetails.id,
        date?.format("YYYY-MM-DD HH:mm:ss")
      );
      await setIsChange((prev) => !prev);
    }
  };

  const canEditDate = permissions?.includes("edit-invoice-date") || false;
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
        <Typography sx={{ fontSize: "12px", fontWeight: 400 }}>
          Invoice ID
        </Typography>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: 600,
            wordBreak: "break-all",
          }}
        >
          {orderDetails?.id}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
        <Typography sx={{ fontSize: "12px", fontWeight: 400 }}>
          Order Type
        </Typography>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: 600,
            textTransform: "capitalize",
          }}
        >
          {orderDetails?.orderType?.name}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
        <Typography sx={{ fontSize: "12px", fontWeight: 400 }}>
          Payment Method
        </Typography>
        <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
          {orderDetails?.paymentMethod?.name}
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
        <Typography sx={{ fontSize: "12px", fontWeight: 400 }}>
          Transaction Time
        </Typography>
        {isUpdateDate ? (
          <Box
            sx={{
              display: "flex",
              gap: 1,
            }}
          >
            <DateTimePicker
              value={date}
              ampm={false}
              onChange={(date) => setDate(date)}
              renderInput={(params) => (
                <TextField size="small" sx={{ width: 205 }} {...params} />
              )}
            />
            <Button
              variant="contained"
              color="primary"
              sx={{ m: 0, p: 0, display: "block" }}
              onClick={handleChangeDate}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="info"
              sx={{ m: 0, p: 0, display: "block" }}
              onClick={() => setIsUpdateDate(false)}
            >
              Cancel
            </Button>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
            }}
          >
            {canEditDate && (
              <Button
                sx={{ p: 0, m: 0 }}
                variant="contained"
                onClick={() => setIsUpdateDate(true)}
              >
                Edit
              </Button>
            )}
            <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
              {dayjs(orderDetails?.createdAt).format("DD/MMM/YY")},{" "}
              {dayjs(orderDetails?.createdAt).format("HH:mm:ss")}
            </Typography>
          </Box>
        )}
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
        <Typography sx={{ fontSize: "12px", fontWeight: 400 }}>
          Transaction Status
        </Typography>
        <Typography
          sx={{
            fontSize: "12px",
            fontWeight: 600,
            color: invoiceStatusLabel(orderDetails?.status).color,
          }}
        >
          {invoiceStatusLabel(orderDetails?.status).name}
        </Typography>
      </Box>
      <Divider sx={{ mt: 1 }} />
    </Box>
  );
};

export default OrderDetailData;
