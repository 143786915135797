import CancelIcon from "@mui/icons-material/Cancel";
import ImageOutlinedIcon from "@mui/icons-material/ImageOutlined";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  deleteSubCategory,
  getCategoryByStatus,
  getDetailSubCategory,
  updateSubCategory,
  uploadImage,
} from "../api/productCategory";
import { ReactComponent as Trash } from "../assets/icons/trash.svg";
import { DrawerHeader } from "../helpers/general";
import { fetchStation, getStationList } from "../store/Station/station";
import CustomAutocomplete from "./shared/CustomAutocomplete";
import CustomButton from "./shared/CustomButton";
import CustomDialog from "./shared/CustomDialog";
import CustomInput from "./shared/CustomInput";
import CustomSelect from "./shared/CustomSelect";

const SubCategoryEditForms = ({ matches, permissions }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { idSubCategory } = useParams();

  //! image
  const [image, setImage] = useState();
  const [isMaxSizeImage, setIsMaxSizeImage] = useState(false);
  const [isUploadImage, setIsUploadImage] = useState(false);
  const [isImagePreview, setIsImagePreview] = useState(false);
  const [imagePreview, setImagePreview] = useState("");
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // ! name
  const [name, setName] = useState("");
  const [errorName, setErrorName] = useState();

  //! category product
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [category, setCategory] = useState(null);
  const [errorCategory, setErrorCateerrorCategory] = useState(false);

  //! stations
  const stationOptions = useSelector(getStationList);
  const [station, setStation] = useState(null);
  const [errorStation, setErrorStation] = useState(false);

  // ! sortNumber
  const [sortNumber, setSortNumber] = useState();
  const [errorSortNumber, setErrorSortNumber] = useState();

  //! status
  const statusOptions = [
    { name: "Active", value: 1 },
    { name: "Inactive", value: 0 },
  ];
  const [status, setStatus] = useState(1);

  // ! fetch detail sub category
  const fetchDetailCategory = async () => {
    try {
      const data = await getDetailSubCategory(idSubCategory);
      setImage(data.data.imageUrl);
      setName(data.data.name);
      setStatus(data.data.status);
      setSortNumber(data.data.sortNumber);
      setStation({ id: data.data.station.id, label: data.data.station.name });
      setCategory({
        id: data.data.productCategory.id,
        label: data.data.productCategory.name,
      });
    } catch (err) {
      console.error(err);
    }
  };

  //! fetch category
  const fetchCategories = async () => {
    const { data } = await getCategoryByStatus();

    setCategoryOptions(data.map(({ id, name }) => ({ id, label: name })));
  };

  //! fetch station
  useEffect(() => {
    fetchDetailCategory();
    dispatch(fetchStation());
    fetchCategories();
  }, []);

  //! --------------------- handle --------------------------
  const handleImage = (e) => {
    const img = e.target.files[0];
    if (img.size > 1000000) {
      setIsMaxSizeImage(true);
    } else {
      setIsUploadImage(true);
      let datas = {
        image: img,
      };
      uploadImage(datas).then((res) => {
        setImage(res.data.data.image_url);
        setIsMaxSizeImage(false);
        setIsUploadImage(false);
      });
    }
  };

  // ! validation submit
  useEffect(() => {
    image && name !== "" && station !== null && category !== null
      ? setIsDisabled(true)
      : setIsDisabled(false);
  }, [image, name, station, category]);

  //! button submit
  const [isDisabled, setIsDisabled] = useState(false);
  const handleSubmit = () => {
    let datas = {
      product_category_id: category?.id,
      station_id: station?.id,
      status: status,
      name: name,
      sort_number: sortNumber,
      image_url: image,
    };

    const updateData = async () => {
      setIsDisabled(true);
      try {
        const data = await updateSubCategory(idSubCategory, datas);
      } catch (err) {
        console.error(err);
      } finally {
        navigate(-1);
        setIsDisabled(false);
      }
    };
    updateData();
  };

  const handleDelete = () => {
    const deletes = async () => {
      try {
        await deleteSubCategory(idSubCategory);
      } catch (err) {
        console.error(err);
      } finally {
        navigate(-1);
      }
    };
    deletes();
  };
  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        gap: 2,
        p: 2,
      }}
    >
      <Box>
        <DrawerHeader />
        <Grid container columns={12} spacing={2}>
          {/* image */}
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Box>
              <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                Photo*
              </Typography>
              <Typography
                sx={{ fontSize: "12px", fontWeight: 500, color: "#9E9D9D" }}
              >
                Format file: JPG, JPEG, PNG, Max file size: 1MB
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "start",
                gap: 2,
                mt: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {!image && (
                  <Box
                    sx={{
                      height: "125px",
                      width: "125px",
                      aspectRatio: "1/1",
                      borderRadius: "8px",
                      bgcolor: "#F5F5F5",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderStyle: "dashed",
                      borderColor: "#9E9D9D",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      document.getElementById("input_img").click();
                    }}
                  >
                    <ImageOutlinedIcon
                      sx={{ color: "#9E9D9D", fontSize: "25px" }}
                    />
                  </Box>
                )}
                {image && (
                  <Box
                    sx={{
                      position: "relative",
                      overflow: "visible",
                      padding: "7px",
                    }}
                  >
                    <CancelIcon
                      sx={{
                        position: "absolute",
                        fontSize: "20px",
                        top: 1,
                        right: 1,
                        zIndex: +1,
                        cursor: "pointer",
                        background: "#FFFFFF",
                        borderRadius: "50%",
                      }}
                      onClick={() => setImage()}
                    />
                    <Box
                      component="img"
                      src={image}
                      sx={{
                        height: "125px",
                        width: "125px",
                        aspectRatio: "1/1",
                        borderRadius: "8px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setIsImagePreview(!isImagePreview);
                        setImagePreview(image);
                      }}
                    />
                  </Box>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {!image && (
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        color: theme.palette.primary.main,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        document.getElementById("input_img").click();
                      }}
                    >
                      <input
                        accept="image/*"
                        id="input_img"
                        type="file"
                        style={{ display: "none" }}
                        onChange={handleImage}
                      />
                      {!isUploadImage ? (
                        <></>
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                          }}
                        >
                          <CircularProgress size={18} />
                          <Typography> uploading...</Typography>
                        </Box>
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Grid>

          {/* name */}
          <Grid item xs={12} sx={{ mt: 2 }}>
            <CustomInput
              label="Sub Category Name*"
              readOnly={!permissions.includes("add-outlet-subCategory")}
              fullWidth
              value={name}
              onChange={(event) => {
                setName(event.target.value);
                setErrorName(false);
              }}
              sx={{
                "& .MuiOutlinedInput-input": {
                  paddingInline: "14px",
                  fontSize: "14px",
                },
              }}
              error={errorName}
              helperText={errorName && "required"}
            />
          </Grid>

          {/* category */}
          <Grid item xs={12} sx={{ mt: 1 }}>
            <CustomAutocomplete
              fullWidth
              label="Category Product*"
              placeholder="Category Product"
              value={category}
              error={errorCategory}
              helperText={errorCategory && "required"}
              onChange={(event, newValue) => {
                setCategory(newValue);
              }}
              options={categoryOptions}
              renderOption={(props, option) => (
                <MenuItem {...props} key={option.id}>
                  {option.label}
                </MenuItem>
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              sx={{
                "& .MuiOutlinedInput-root": {
                  fontSize: "0.875rem",
                },
                "& .MuiButtonBase-root": {
                  p: 0,
                  pt: "2px",
                },
              }}
            />
          </Grid>

          {/* stattion */}
          <Grid item xs={12} sx={{ mt: 1 }}>
            <CustomAutocomplete
              fullWidth
              label="Station*"
              placeholder="Station"
              value={station}
              error={errorStation}
              helperText={errorStation && "required"}
              onChange={(event, newValue) => {
                setStation(newValue);
              }}
              options={stationOptions}
              renderOption={(props, option) => (
                <MenuItem {...props} key={option.id}>
                  {option.label}
                </MenuItem>
              )}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              sx={{
                "& .MuiOutlinedInput-root": {
                  fontSize: "0.875rem",
                },
                "& .MuiButtonBase-root": {
                  p: 0,
                },
              }}
            />
          </Grid>

          {/* sort number */}
          <Grid item xs={6} sx={{ mt: 1 }}>
            <TextField
              id="outlined-number"
              label="Sort Number"
              type="number"
              value={sortNumber}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => {
                setSortNumber(event.target.value);
                setErrorSortNumber(false);
              }}
              sx={{
                "& .MuiOutlinedInput-input": {
                  paddingInline: "14px",
                  fontSize: "12px",
                },
              }}
            />
          </Grid>

          {/* status */}
          <Grid item xs={6} sx={{ mt: 1 }}>
            <CustomSelect
              fullWidth
              label="Status*"
              value={status}
              onChange={(event) => setStatus(event.target.value)}
              options={statusOptions}
              sx={{
                "& .MuiOutlinedInput-input": {
                  paddingInline: "14px",
                  fontSize: "0.875rem",
                },
              }}
            />
          </Grid>
        </Grid>
      </Box>

      {/* button submit */}
      <Box sx={{ display: "flex", gap: 2 }}>
        <CustomButton
          onClick={() => setIsDeleteDialogOpen(true)}
          type="submit"
          fullWidth
          variant="contained"
          color="error"
        >
          <Typography sx={{ color: "#FFFFFF" }}>Delete</Typography>
        </CustomButton>
        <CustomButton
          onClick={handleSubmit}
          disabled={!isDisabled}
          type="submit"
          fullWidth
          variant="contained"
        >
          Save
        </CustomButton>
      </Box>
      {/* dialog image */}
      <Dialog
        open={isImagePreview}
        onClose={() => {
          setIsImagePreview();
          setImagePreview("");
        }}
      >
        <Box
          component="img"
          src={imagePreview}
          sx={{ maxWidth: "600px", maxHeight: "600px", aspectRation: "1/1" }}
        />
      </Dialog>

      {/* custom Detele */}
      <CustomDialog
        isOpen={isDeleteDialogOpen}
        setIsOpen={setIsDeleteDialogOpen}
      >
        <DialogTitle>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 2,
              padding: "30px 0px 0px 35px",
            }}
          >
            <Box
              sx={{
                width: "43px",
                height: "43px",
                background: " rgba(243, 58, 58, 0.1)",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Trash />
            </Box>
            <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
              Delete Sub Category
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ padding: "10px 0px 50px 35px" }}>
            <Typography sx={{ fontSize: "14px" }}>
              Are you sure you want to delete changes?
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              padding: "30px 55px 0px 35px",
            }}
          >
            <Box
              onClick={() => setIsDeleteDialogOpen(false)}
              sx={{ width: "100px", cursor: "pointer" }}
            >
              <Typography
                sx={{ fontSize: "14px", color: "#9E9D9D", fontWeight: 600 }}
              >
                Back
              </Typography>
            </Box>

            <CustomButton
              onClick={handleDelete}
              disabled={isLoading}
              type="submit"
              variant="contained"
              color="error"
              sx={{ width: "100px" }}
            >
              <Typography sx={{ color: "#FFFFFF" }}>Delete</Typography>
            </CustomButton>
          </Box>
        </DialogContent>
      </CustomDialog>
    </Box>
  );
};
export default SubCategoryEditForms;
