import {
  Backdrop,
  Box,
  Button,
  Divider,
  Fade,
  Modal,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { cancelOrderDetail } from "../api/orderDetail";
import { numberWithCommas } from "../helpers/general";
import useAuth from "../hooks/useAuth";
import CustomCard from "./shared/CustomCard";

const OrderPaymentDetail = ({ orderDetails, setIsChange }) => {
  const [open, setOpen] = useState(false);
  const { permissions } = useAuth();

  const canCancel = permissions?.includes("cancel-invoice") || false;

  const handleCancelInvoice = async () => {
    if (orderDetails?.id) {
      await cancelOrderDetail(orderDetails.id);
      await setIsChange((prev) => !prev);
    }
  };

  return (
    <Box>
      {/* -- Modal --- */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 4,
            }}
          >
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Cancel Invoice ?
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              Are you sure you want to cancel this invoice? This action cannot
              be undone.
            </Typography>
            <Box sx={{ mt: 4, display: "flex" }}>
              <Button
                variant="contained"
                color="info"
                onClick={() => setOpen(false)}
              >
                Close
              </Button>
              <Button
                sx={{ ml: "auto" }}
                variant="contained"
                color="warning"
                onClick={handleCancelInvoice}
              >
                Cancel
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
      {/* -- Modal --- */}

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          paddingBottom: "15px",
        }}
      >
        <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
          Payment Detail
        </Typography>
        {[100, 200, 201].includes(orderDetails?.status) && canCancel && (
          <Button
            type="button"
            color="warning"
            variant="contained"
            sx={{
              marginLeft: "auto",
            }}
            onClick={() => setOpen(true)}
          >
            Cancel Invoice
          </Button>
        )}
      </Box>

      <CustomCard sx={{ p: 0 }}>
        <Box
          sx={{
            p: 1.5,
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Box
            sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}
          >
            <Typography sx={{ fontSize: "12px", fontWeight: 400 }}>
              Subtotal
            </Typography>
            <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
              {numberWithCommas(orderDetails?.subtotal)}
            </Typography>
          </Box>
          {orderDetails?.invoicePriceDetails?.map((invoice, index) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 1,
                }}
                key={index}
              >
                <Typography sx={{ fontSize: "12px", fontWeight: 400 }}>
                  {invoice?.name}
                </Typography>
                <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                  {numberWithCommas(invoice?.amount)}
                </Typography>
              </Box>
            );
          })}
        </Box>
        <Divider />
        <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1 }}>
          <Typography sx={{ fontSize: "16px", fontWeight: 600, p: 1.5 }}>
            Grand Total
          </Typography>
          <Typography sx={{ fontSize: "16px", fontWeight: 600, p: 1.5 }}>
            Rp{numberWithCommas(orderDetails?.grandTotal)}
          </Typography>
        </Box>
      </CustomCard>
    </Box>
  );
};

export default OrderPaymentDetail;
