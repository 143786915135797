import { Box, CircularProgress, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getOrderDetail } from "../api/orderDetail";
import OrderDetailData from "../components/OrderDetailData";
import OrderPaymentDetail from "../components/OrderPaymentDetail";
import OrderProductDetail from "../components/OrderProductDetail";
import CustomAppBarBack from "../components/shared/CustomAppBarBack";
import { DrawerHeader } from "../helpers/general";

const OrderDetail = () => {
  const [orderDetails, setOrderDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { orderId } = useParams();
  const [isChange, setIsChange] = useState(false);

  useEffect(() => {
    const fetchOrderDetail = async () => {
      try {
        setIsLoading(true);
        const {
          data: {
            id,
            orderType,
            paymentMethod,
            createdAt,
            status,
            orders,
            subtotal,
            invoicePriceDetails,
            grandTotal,
          },
        } = await getOrderDetail(orderId);
        setOrderDetails({
          id,
          orderType,
          paymentMethod,
          createdAt,
          status,
          orders,
          subtotal,
          invoicePriceDetails,
          grandTotal,
        });
      } catch {
      } finally {
        setIsLoading(false);
      }
    };
    fetchOrderDetail();
  }, [orderId, isChange]);

  return (
    <Box sx={{ minHeight: "100vh", width: "100vw" }}>
      <CustomAppBarBack title="Invoice Detail" />
      <DrawerHeader />
      <Container maxWidth="lg" disableGutters>
        {isLoading && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "500px",
              transform: "scaleX(-1)",
            }}
          >
            <CircularProgress />
          </Box>
        )}

        {!isLoading && !orderDetails && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "calc(100vh - 3.7rem)",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            No Order Detail found
          </Box>
        )}

        {!isLoading && orderDetails && (
          <Box sx={{ p: 2, display: "flex", flexDirection: "column", gap: 2 }}>
            <OrderDetailData
              orderDetails={orderDetails}
              setIsChange={setIsChange}
            />
            <OrderPaymentDetail
              orderDetails={orderDetails}
              setIsChange={setIsChange}
            />
            <OrderProductDetail orderDetails={orderDetails} />
          </Box>
        )}
      </Container>
    </Box>
  );
};

export default OrderDetail;
